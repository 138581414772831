import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GoToGame from "./GoToGame";
import { respondTo } from "../../../theme/mixin";

import { useSelector } from "react-redux";
import useSpring from "../../../hooks/useSpring";
import useNatale from "../../../hooks/useNatale";
import { Link } from "react-router-dom";
import Button from "../Button";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { fontSize } from "styled-system";
import moment from "moment";
import useCarnevale from "../../../hooks/useCarnevale";

const ViewerWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  .partecipa-img {
    position: absolute;
    top: 10px;
    right: 0px;
    max-width: 120px;
    z-index: -1;
  }
  .partecipa-box-wrapper {
    z-index: 1;
    background-color: #6cb967;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    .partecipa-box-header {
      margin-bottom: 10px;
      h4 {
        font-size: 21px;
        color: #fff;
        font-weight: 700;
      }
    }
    .partecipa-box-body {
      display: flex;
      flex-wrap: wrap;
      .box-body {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        ${respondTo.md`
          width: ${({ spring, natale, carnevale }) =>
            spring || natale || carnevale ? "100%" : "50%"};
        `}
        img {
          width: 50%;
          height: auto;
          max-width: 140px;
        }
        img.second {
          margin-left: auto;
        }
        .box-body-info {
          width: 50%;
          margin-top: 20px;
          span {
            color: #fff;
            font-size: 28px;
            font-weight: 300;
            display: block;
            margin-bottom: 10px;
          }
        }
      }
    }
    .partecipa-box-footer {
      text-align: center;
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      p {
        color: #fff;
        font-size: 28px;
        font-weight: 300;
      }
      a {
        display: inline-block;
        position: relative;
        margin-top: 20px;

        ${respondTo.md`
          position: ${({ spring, natale, carnevale }) =>
            spring || natale || carnevale ? "static" : "absolute"};
          right: 0;
          top: 0;
          margin-top:  ${({ spring, natale, carnevale }) =>
            spring || natale || carnevale ? "20px" : "0"};
        `}
      }
      button {
        color: #6cb967;
      }
    }
    ${respondTo.md`
     width: ${({ spring, natale, carnevale }) =>
       spring || natale || carnevale ? "49%" : "100%"};
    `}
  }
  .carnevale {
    img {
      width: 100%;
    }
    width: 100%;
    position: relative;
    border-radius: 15px;
    margin-top: 15px;
    overflow: hidden;
    ${respondTo.md`
     width: 49%;
     margin-top:0;
    `}
    .content-carnevale {
      padding-left: 15px;
      padding-top: 15px;
      width: 42%;
      height: 95%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;

      ${respondTo.sm`
        // width: 45%;
    `}
      @media (max-width: 390px) {
        width: 52%;
      }
    }
    .title-carnevale {
      font-size: 16px;
      font-weight: 700;
      color: #6cb967;
      margin-bottom: 0;
      width: fit-content;
      white-space: nowrap;
      ${respondTo.sm`
       font-size: 21px;
       margin-bottom: 20px;
       white-space:normal;
    `}
      @media (max-width: 390px) {
        font-size: 16px;
      }
    }
    .subTitle-carnevale {
      font-size: 18px;
      color: #185e14;
      margin: 5px 0;

      width: 120%;
      ${respondTo.sm`
       font-size: 28px;
       margin: 20px 0;       
       width:100%;
      
    `}
      @media (max-width: 390px) {
        font-size: 18px;
      }
    }
    .text-carnevale {
      margin-bottom: 10px;
    }
  }

  .spring,
  .natale {
    width: 100%;
    position: relative;
    border-radius: 15px;
    margin-top: 15px;
    overflow: hidden;
    .img-spring {
      @media (max-width: 391px) {
        min-height: 390px;
        object-fit: cover;
        object-position: right;
      }
    }
    ${respondTo.md`
     width: 49%;
     margin-top:0;
    `}
    .content-spring {
      padding-left: 15px;
      padding-top: 15px;
      width: 49%;
      height: 95%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;

      ${respondTo.sm`
      width: 40%;
    `}
      @media (max-width: 360px) {
        width: 53%;
      }
    }
    .title-spring {
      font-size: 16px;
      font-weight: 700;
      color: #6cb967;
      margin-bottom: 0;
      width: fit-content;
      // white-space: nowrap;
      ${respondTo.lg`
       font-size: 21px;
       margin-bottom: 20px;
       width:140px;
      // white-space:normal;
    `}
      @media (max-width: 390px) {
        font-size: 16px;
        width: fit-content;
      }
      @media (min-width: 767px) and (max-width: 1023px) {
        font-size: 21px;
        margin-bottom: 20px;
        width: 140px;
      }
    }
    .subTitle-spring {
      font-size: 28px;
      color: #185e14;
      margin: 5px 0;

      width: 120%;
      ${respondTo.lg`
       font-size: 28px;
       margin: 20px 0;       
       width:100%;
      
    `}
      @media (max-width: 390px) {
        font-size: 18px;
      }
      @media (min-width: 767px) and (max-width: 1023px) {
        font-size: 28px;
        margin: 20px 0;
        width: 100%;
      }
    }
    .text-spring {
      margin-bottom: 10px;
    }
  }
  .link {
    position: absolute;
    bottom: 15px;
    left: 15px;

    button {
      @media (max-width: 391px) {
        height: auto;
        font-size: 12px;
      }
    }
  }
  .back-box-wrapper {
    width: 100%;
    position: relative;
    padding: 15px 15px 15px 0;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 15px;
    ${respondTo.md`
      margin-top: 0;
      width: calc(50% - 15px);
      padding: 15px;
    `}
    & > img {
      position: absolute;
      top: 0;
      left: 55px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      ${respondTo.md`
        left: 0;
      `}
    }
    .back-box-header {
      font-size: 21px;
      color: #6cb967;
      font-weight: 700;
    }
    .back-box-body {
      width: 200px;
      margin-top: 80px;
      ${respondTo.md`
        width: 255px;
      `}
      h5 {
        font-size: 21px;
        color: #185e14;
        ${respondTo.md`
          font-size: 28px;
        `}
      }
      p {
        margin-top: 70px;
      }
    }
    .back-box-footer {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      margin-top: 20px;
      ${respondTo.md`
        position: absolute;
        bottom: 20px;
        margin-top: 0;
      `}
    }
  }
  .text {
    font-size: 12px;
    margin-bottom: 10px;
    ${respondTo.sm`
        font-size:14px;
        margin-bottom:20px;
      `}
  }
  .register {
    font-size: 10px;
    color: #adb1bc;
    margin-top: 15px;
    ${respondTo.md`
        margin-top: 30px;
      `}
  }
  .register__link {
    color: #eb5a3f;
    text-decoration: underline;
  }
`;

const Viewer = (props) => {
  const {
    scream,
    description,
    type,
    islogged,
    logo,
    logopromo,
    url,
    cta,
    iscta,
    //ctatype,
    dark_text,
    oauths,
    ownlogin,
    oauthinfo,
    idFirstGame,
    pathname,
    multipromo,
  } = props;
  const spring = useSpring();
  const natale = useNatale();
  const carnevale = useCarnevale();
  const ctatype = "game";
  //check date now and date change game images
  let dataChangeGame = moment("2024-03-28T00:00:00");
  let now = moment();
  let newVinciPrize = false;
  if (now.isBefore(dataChangeGame)) {
  } else if (now.isAfter(dataChangeGame)) {
    newVinciPrize = true;
  } else {
    newVinciPrize = true;
  }

  return (
    <ViewerWrapper spring={spring} natale={natale} carnevale={carnevale}>
      <div className="partecipa-box-wrapper">
        {!spring && !natale && !carnevale && (
          <img
            src={process.env.PUBLIC_URL + "/images/partecipaUser.png"}
            alt=""
            className="partecipa-img"
          />
        )}

        <div className="partecipa-box-header">
          <h4>Partecipa al concorso Vinci ancora di +</h4>
        </div>
        <div className="partecipa-box-body">
          <div className="box-body">
            <img
              src={process.env.PUBLIC_URL + "/images/lunediParticipa.png"}
              alt=""
            />
            <div className="box-body-info">
              <span>Tutti i lunedì</span>
              <p>
                in palio un <strong>Buono Regalo Amazon.it* da 150€</strong>
              </p>
            </div>
          </div>
          <div className="box-body">
            <img
              src={
                newVinciPrize
                  ? process.env.PUBLIC_URL + "/images/giovediPartecipaNew.png"
                  : process.env.PUBLIC_URL + "/images/giovediPartecipa.png"
              }
              alt=""
              className={
                spring || natale || carnevale
                  ? "second giovediImg"
                  : "giovediImg"
              }
            />
            <div className="box-body-info">
              <span>Tutti i giovedì</span>
              <p>
                in palio un {newVinciPrize ? " paio di" : ""}{" "}
                <strong>
                  {newVinciPrize ? "Cuffie on ear BOSE" : "Microonde Sage"}
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div className="partecipa-box-footer">
          <p>Linkem ti premia sempre di +</p>

          {idFirstGame && (
            <GoToGame
              isLogged={islogged}
              logo={logo}
              url={url}
              cta={cta}
              ctatype={ctatype}
              oauths={oauths}
              ownlogin={ownlogin}
              oauthinfo={oauthinfo}
              idgame={idFirstGame}
              pathname={pathname}
              multipromo={multipromo}
            />
          )}
        </div>
      </div>
      {carnevale && (
        <div className="carnevale">
          <img
            src={process.env.PUBLIC_URL + "/images/boxcarnevale.png"}
            alt=""
          />
          <div className="content-carnevale">
            <h4 className="title-carnevale">Concorso di Carnevale</h4>
            <p className="subTitle-carnevale" style={{ color: "#185E14" }}>
              Festeggia il Carnevale con noi
            </p>

            <p className="text">
              <strong>Dal 9 al 13 febbraio,</strong> partecipa al nostro
              concorso di Carnevale e prova a vincere fantastici premi.
              <br /> <strong>Ogni giorno</strong> in palio per te
              <br /> una{" "}
              <strong>
                Gift Card digitale <br /> Volagratis del valore di 350€.
              </strong>
              <br /> Cosa aspetti? Partecipa subito
            </p>
          </div>
          <Link
            to={{
              pathname: multipromo
                ? `${pathname}/game/${+process.env.REACT_APP_GAME_CARNEVALE}`
                : `/promo/game/${+process.env.REACT_APP_GAME_CARNEVALE}`,
              state: { cid: "boxgotogame-viewer" },
            }}
            className="link"
          >
            <Button upper gradient={{ left: "#6CB967", right: "#96F290" }}>
              Gioca <ChevronRight size={30} />
            </Button>{" "}
          </Link>
        </div>
      )}
      {spring && (
        <div className="spring">
          <img
            className="img-spring"
            src={process.env.PUBLIC_URL + "/images/boxspring.png"}
            alt=""
          />
          <div className="content-spring">
            <h4 className="title-spring">
              Concorso <br /> di Primavera
            </h4>
            <p className="subTitle-spring" style={{ color: "#185E14" }}>
              Festeggia la primavera con noi!
            </p>

            <p className="text">
              <strong>Dal 27 al 30 maggio</strong>, partecipa al nostro concorso
              «Fai volare l’aquilone» e prova a vincere fantastici premi.
              <br />
              Ogni giorno in palio per te{" "}
              <strong>due Cuffie Wireless Sony</strong>. <br />
              Cosa aspetti? Partecipa subito
              <br />
              <br />
            </p>
          </div>
          <Link
            to={{
              pathname: multipromo
                ? `${pathname}/game/${+process.env.REACT_APP_GAME_SPRING}`
                : `/promo/game/${+process.env.REACT_APP_GAME_SPRING}`,
              state: { cid: "boxgotogame-viewer" },
            }}
            className="link"
          >
            <Button upper gradient={{ left: "#6CB967", right: "#96F290" }}>
              Gioca <ChevronRight size={30} />
            </Button>{" "}
          </Link>
        </div>
      )}
      {natale && (
        <div className="natale">
          <img
            className="img-spring"
            src={process.env.PUBLIC_URL + "/images/boxnatale.png"}
            alt=""
          />
          <div className="content-spring">
            <h4 className="title-spring">Concorso di natale</h4>
            <p className="subTitle-spring" style={{ color: "#185E14" }}>
              A Christmas Game
            </p>

            <p className="text">
              <strong>Dal 13 al 19 dicembre</strong>
              <br />
              partecipa al nostro concorso di Natale a tema Mercante in Fiera.{" "}
              <strong>Ogni giorno</strong> in palio per te un{" "}
              <strong>
                Buono Regalo Digitale Amazon.it* del valore di 500€.{" "}
              </strong>{" "}
              In più partecipi all’estrazione finale di un
              <strong> super premio:</strong> <br />
              un
              <strong> voucher Bliss Gioielli del valore di 5.000€.</strong>
            </p>
            <p className="register">
              *Restrizioni applicate. Vedere dettagli su: 
              <br />{" "}
              <a
                href="https://www.amazon.it/gc-legal"
                className="register__link"
              >
                {" "}
                amazon.it/gc-legal
              </a>
            </p>
          </div>
          <Link
            to={{
              pathname: multipromo
                ? `${pathname}/game/${+process.env.REACT_APP_GAME_NATALE}`
                : `/promo/game/${+process.env.REACT_APP_GAME_NATALE}`,
              state: { cid: "boxgotogame-viewer" },
            }}
            className="link"
          >
            <Button upper gradient={{ left: "#6CB967", right: "#96F290" }}>
              Gioca <ChevronRight size={30} />
            </Button>{" "}
          </Link>
        </div>
      )}
    </ViewerWrapper>
  );
};

Viewer.propTypes = {
  scream: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  isLogged: PropTypes.string,
  logo: PropTypes.string,
  logopromo: PropTypes.string,
  url: PropTypes.string,
  cta: PropTypes.string,
  iscta: PropTypes.number,
  ctatype: PropTypes.string,
};

export default Viewer;
