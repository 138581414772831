import * as actionTypes from "./actionTypes";
import customaxios from "../../config/axios-refresh-token";

import { sendLogError } from "./error";

import axios from "axios";

let source = axios.CancelToken.source();

const set404error = (status) => {
  return {
    type: actionTypes.SET_PROMO_404_ERROR,
    status,
  };
};

export const reset404error = () => {
  return {
    type: actionTypes.RESET_PROMO_404_ERROR,
  };
};

const getHighlightsPromoStart = () => {
  return {
    type: actionTypes.GET_HIGHLIGHTS_PROMO_START,
  };
};

const getHighlightsPromoSuccess = (promo) => {
  return {
    type: actionTypes.GET_HIGHLIGHTS_PROMO_SUCCESS,
    promo,
  };
};

const getHighlightsPromoError = (error) => {
  return {
    type: actionTypes.GET_HIGHLIGHTS_PROMO_ERROR,
    error,
  };
};

export const getHighlightsPromo = () => {
  let endpoint = `promo/highlights`;
  return (dispatch) => {
    dispatch(getHighlightsPromoStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(getHighlightsPromoSuccess(result.data));
      })
      .catch((error) => {
        dispatch(getHighlightsPromoError(error.response.status));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getAllPromoStart = () => {
  return {
    type: actionTypes.GET_ALL_PROMO_START,
  };
};

const getAllPromoSuccess = (promo) => {
  return {
    type: actionTypes.GET_ALL_PROMO_SUCCESS,
    promo,
  };
};

const getAllPromoError = (error) => {
  return {
    type: actionTypes.GET_ALL_PROMO_ERROR,
    error,
  };
};

export const getAllPromo = () => {
  let endpoint = `promo/more`;
  return (dispatch) => {
    dispatch(getAllPromoStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(getAllPromoSuccess(result.data));
      })
      .catch((error) => {
        dispatch(getAllPromoError(error.response.status));

        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getPromoViewerStart = () => {
  return {
    type: actionTypes.GET_PROMO_VIEWER_START,
  };
};

const getPromoViewerSuccess = (viewer) => {
  return {
    type: actionTypes.GET_PROMO_VIEWER_SUCCESS,
    viewer,
  };
};

const getPromoViewerError = (error) => {
  return {
    type: actionTypes.GET_PROMO_VIEWER_ERROR,
    error,
  };
};

export const getPromoViewer = (slug) => {
  let endpoint = `promo/get/${slug}/viewer`;
  return (dispatch) => {
    dispatch(getPromoViewerStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(getPromoViewerSuccess(result.data));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(set404error(error.response.status));
        } else {
          dispatch(getPromoViewerError(error.response.status));
        }
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

export const cancelGetPromo = () => {
  return (dispatch) => {
    source.cancel("cancel request");
  };
};

export const getPromoViewerReset = () => {
  return {
    type: actionTypes.GET_PROMO_VIEWER_RESET,
  };
};

const getPromoInfoStart = () => {
  return {
    type: actionTypes.GET_PROMO_INFO_START,
  };
};

const getPromoInfoLoading = () => {
  return {
    type: actionTypes.GET_PROMO_INFO_LOADING,
  };
};

const getPromoInfoSuccess = (promoinfo) => {
  return {
    type: actionTypes.GET_PROMO_INFO_SUCCESS,
    promoinfo,
  };
};

const getPromoInfoError = (error) => {
  return {
    type: actionTypes.GET_PROMO_INFO_ERROR,
    error,
  };
};

export const getPromoInfo = (slug, cid) => {
  let endpoint = `promo/get/${slug}`;
  return (dispatch) => {
    dispatch(getPromoInfoStart());
    customaxios
      .post(endpoint, {
        cid,
      })
      .then((response) => {
        dispatch(getPromoInfoSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(set404error(error.response.status));
        } else {
          dispatch(getPromoInfoError(error.response.status));
        }
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getCmsIntroStart = () => {
  return {
    type: actionTypes.GET_CMS_INTRO_START,
  };
};
const getCmsIntroSuccess = (data) => {
  return {
    type: actionTypes.GET_CMS_INTRO_SUCCESS,
    data,
  };
};
const getCmsIntroError = (error) => {
  return {
    type: actionTypes.GET_CMS_INTRO_ERROR,
    error,
  };
};

export const getCmsIntro = (slug) => {
  let endpoint = `cms/promo/${slug}/intro`;
  return (dispatch) => {
    dispatch(getCmsIntroStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(getCmsIntroSuccess(result.data));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(set404error(error.response.status));
        } else {
          dispatch(getCmsIntroError(error.response.status));
        }
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getGamesStart = () => {
  return {
    type: actionTypes.GET_GAMES_START,
  };
};

const getGamesSuccess = (games) => {
  return {
    type: actionTypes.GET_GAMES_SUCCESS,
    games,
  };
};

const getGamesError = (error) => {
  return {
    type: actionTypes.GET_GAMES_ERROR,
    error,
  };
};

export const getGames = (slug, isLogged) => {
  let endpoint = `games/${slug}/all`;
  if (isLogged) {
    endpoint = `games/${slug}/jwt`;
  }

  return (dispatch) => {
    dispatch(getGamesStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(getGamesSuccess(result.data));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(set404error(error.response.status));
        } else {
          dispatch(getGamesError(error.response.status));
        }
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getGameStart = () => {
  return {
    type: actionTypes.GET_GAME_START,
  };
};

const getGameSuccess = (game) => {
  return {
    type: actionTypes.GET_GAME_SUCCESS,
    game,
  };
};

const getGameError = (error) => {
  return {
    type: actionTypes.GET_GAME_ERROR,
    error,
  };
};

export const getGame = (id, slug, cid, skip = "") => {
  let endpoint = `/game/${slug}/${id}`;
  return (dispatch) => {
    dispatch(getGameStart());
    const data = {
      cid,
    };

    if (skip) {
      data.checkDates = skip;
    }
    customaxios
      .post(endpoint, data)
      .then((result) => {
        if (result.data.error === 1010) {
          alert(result.data.error.status);
        } else {
          dispatch(getGameSuccess(result.data));
        }
      })
      .catch((error) => {
        /*   if (error.response.status === 404) {
          dispatch(set404error(error.response.status));
        } else {
          dispatch(getGameError(error.response.status));
        }
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        } */
        console.log({ error });
      });
  };
};

export const setGameRetry = () => {
  return {
    type: actionTypes.SET_GAME_RETRY,
  };
};

const getPromoTagStart = () => {
  return {
    type: actionTypes.GET_PROMO_TAG_START,
  };
};

const getPromoTagSuccess = (taginfo) => {
  return {
    type: actionTypes.GET_PROMO_TAG_SUCCESS,
    taginfo,
  };
};

const getPromoTagError = (error) => {
  return {
    type: actionTypes.GET_PROMO_TAG_ERROR,
    error,
  };
};

export const getPromoTag = (slugtag, cid) => {
  let endpoint = `/promo/tag/${slugtag}`;
  return (dispatch) => {
    dispatch(getPromoTagStart());
    customaxios
      .post(endpoint, { cid })
      .then((result) => {
        dispatch(getPromoTagSuccess(result.data));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(set404error(error.response.status));
        } else {
          dispatch(getPromoTagError(error.response.status));
        }
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getPromoTagViewerStart = () => {
  return {
    type: actionTypes.GET_PROMO_TAG_VIEWER_START,
  };
};

const getPromoTagViewerSuccess = (viewer) => {
  return {
    type: actionTypes.GET_PROMO_TAG_VIEWER_SUCCESS,
    viewer,
  };
};

const getPromoTagViewerError = (error) => {
  return {
    type: actionTypes.GET_PROMO_TAG_VIEWER_ERROR,
    error,
  };
};

export const getPromoTagViewer = (slug) => {
  let endpoint = `promo/tag/${slug}/viewer`;
  return (dispatch) => {
    dispatch(getPromoTagViewerStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(getPromoTagViewerSuccess(result.data));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(set404error(error.response.status));
        } else {
          dispatch(getPromoTagViewerError(error.response.status));
        }
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};
