import React, { useEffect, useState } from "react";
import { Redirect, Route, Router, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { createBrowserHistory } from "history";
import { LastLocationProvider } from "react-router-last-location";

import AuthLayout from "../layouts/AuthLayout";
import BaseLayout from "../layouts/BaseLayout";

import ReactGA from "react-ga4";
import styled from "styled-components";

import Login from "../views/Login";
import Register from "../views/Register";
import RecoveryPassword from "../views/RecoveryPassword";
import ResetPassword from "../views/ResetPassword";
import Profile from "../views/Profile";
import Initiative from "../views/Initiative";
import UserActivated from "../views/UserActivated";
import Game from "../views/Game";
import Oauth from "../views/Oauth";
import Panel from "../views/Panel";
import Awards from "../views/Awards";
import NotFound from "../views/NotFound";
import Contact from "../views/Contact";
import HowToWork from "../views/HowToWork";
import AreaPartner from "../views/AreaPartner";
import InviteFriend from "../views/InviteFriend/InviteFriend";
import Product from "../views/Product/Product";
import Catalog from "../views/Catalog/Catalog";
import Wishlist from "../views/Wishlist/Wishlist";
import Cart from "../views/Cart/Cart";
import SinglePartner from "../views/SinglePartner/SinglePartner";
import Surveys from "../views/Surveys/Surveys";
import PrizeIw from "../views/PrizeIw";
import WinMore from "../views/WinMore/WinMore";
import Faq from "../views/Faq/Faq";
import { Link } from "react-router-dom";
import { Modal, Button } from "../components/Ui";
import FirstAccess from "../views/FirstAccess/FirstAccess";
import RushAndWin from "../views/RushAndWin/RushAndWin";
import FormValidation from "../views/FormValidation/FormValidation";
import { isWinExpires } from "../utils/utils";
import ContestPrize from "../views/ContestPrize/ContestPrize";
import FakeLogin from "../views/FakeLogin/FakeLogin";
import useSpring from "../hooks/useSpring";
import { useSelector, useDispatch } from "react-redux";
import { use } from "i18next";
import useNatale from "../hooks/useNatale";
import useCarnevale from "../hooks/useCarnevale";
import * as actionCreators from "../store/actions";
import customaxios from "../config/axios-refresh-token";
const history = createBrowserHistory();

const ConvalidContent = styled.div`
  text-align: center;

  p {
    line-height: 1.4;
  }

  a {
    margin: 15px 0;
    display: block;
  }
`;

const PrivateRoute = ({ component: Component, layoutCmp: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isLogged !== null ? (
          <Layout {...props} {...rest} cmp={Component} />
        ) : (
          (window.location = "https://mylinkempage.linkem.com/MyLinkPlus")
        )
      }
    />
  );
};

const Routes = (props) => {
  const { isLogged, ownLogin, multipromo, promoId, userInfo, inGame, wins } =
    props;

  history.listen((location) => {
    let pathToGA = location.pathname;
    if (pathToGA === "/" && isLogged != null) {
      ReactGA.ga("set", "page", "/logged");
    } else {
      ReactGA.ga("set", "page", pathToGA);
    }
    ReactGA.ga("send", "pageview");
  });

  useEffect(() => {
    if (isLogged != null) {
      if (window.location.pathname === "/") {
        ReactGA.ga("set", "page", "/logged");
      }
    } else {
      ReactGA.ga("set", "page", window.location.pathname);
    }
    ReactGA.ga("send", "pageview");
  }, [isLogged]);

  const [showCM, setShowCM] = useState(false);

  const spring = useSpring();
  const carnevale = useCarnevale();
  const halloween = useNatale();
  useEffect(() => {
    if (wins) {
      const find = wins.find(
        (win) =>
          win.pending === true &&
          win.game_type !== "survey" &&
          win.game_type !== "quiz" &&
          userInfo.accept_launch_contest_rules_23
      );

      if (find && !inGame) {
        setShowCM(true);
      }
    }
  }, [wins, userInfo]);

  let ownRoutes = null;
  const closeCMHandler = () => {
    setShowCM(false);
  };

  if (ownLogin) {
    ownRoutes = (
      <React.Fragment>
        {/* <Route
          path="/auth/recovery-password"
          exact
          render={(route) => <AuthLayout cmp={RecoveryPassword} {...route} />}
        />
        <Route
          path="/auth/register"
          exact
          render={(route) => <AuthLayout cmp={Register} {...route} />}
        />
        <Route
          path="/activate/:id"
          exact
          render={(route) => <AuthLayout cmp={UserActivated} {...route} />}
        />
        <Route
          path="/reset-password/:onetimetoken"
          exact
          render={(route) => <AuthLayout cmp={ResetPassword} {...route} />}
        /> */}
        <Route
          path="/login/:token"
          render={(route) => <AuthLayout cmp={Login} {...route} />}
        />
        <Route
          path="/impersonate/:token"
          render={(route) => <AuthLayout cmp={FakeLogin} {...route} />}
        />
      </React.Fragment>
    );
  }
  const [showNewRegulationModal, setshowNewRegulationModal] = useState(false);
  const [showPopupExtensionModal, setshowPopupExtensionModal] = useState(false);
  const consents = useSelector((state) => state.user?.user?.consents);
  const acceptNewRegulation = consents?.some(
    (ele) => ele.consent_name === "proroga_concorso_2024"
  );

  //consents
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    const popupRegulation = localStorage.getItem("popupRegulation");
    if (!popupRegulation && user.first_access_done) {
      setshowNewRegulationModal(true);
      localStorage.setItem("popupRegulation", "true");
    }
  }, [user.first_access_done]);

  //function to verify date actual
  const checkIfPastTargetDate = () => {
    const targetDate = moment("2024-06-28T00:00:00");
    const now = moment();

    return now.isAfter(targetDate);
  };
  //nuovo popup
  useEffect(() => {
    if (!acceptNewRegulation && checkIfPastTargetDate()) {
      setshowPopupExtensionModal(true);
    } else {
      setshowPopupExtensionModal(false);
    }
  }, [acceptNewRegulation, consents]);

  const closeModalRegulation = function () {
    setshowNewRegulationModal(false);
  };
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const closePopupExtensionModal = () => {
    setshowPopupExtensionModal(false);
    let endpoint = `auth/me/consent`;
    customaxios
      .post(endpoint, { consent_name: "proroga_concorso_2024" })
      .then((result) => {
        dispatch(actionCreators.getUserInfo());
      })
      .catch((error) => {});
  };
  return (
    <Router history={history}>
      {showCM ? (
        <Modal show={showCM} close={() => closeCMHandler()}>
          <ConvalidContent>
            <p>Hai un premio da convalidare?</p>
            <Link
              // to={`/promo/vincidipiu/convalida`}
              to={`/promo/vinciancoradipiu/convalida`}
              onClick={() => closeCMHandler()}
            >
              <Button
                upper
                gradient={{ left: "#6CB967", right: "#96F290" }}
                hoverGradient={{ left: "#96F290", right: "#96F290" }}
              >
                Si, voglio convalidarlo
              </Button>
            </Link>
          </ConvalidContent>
        </Modal>
      ) : null}

      {showPopupExtensionModal ? (
        <Modal
          show={showPopupExtensionModal}
          close={() => closePopupExtensionModal()}
        >
          <p>
            Gentile utente, ti informiamo che il programma è stato prorogato e i
            punti potranno essere utilizzati fino al 15 luglio 2025. Per
            ulteriori informazioni consulta il{" "}
            <Link
              target={"_blank"}
              to={
                process.env.PUBLIC_URL +
                "/pdf/Regolamento_OP_MyLinkPlus_2021-2022-2023-2024-2025.pdf"
              }
              onClick={() => closePopupExtensionModal()}
            >
              regolamento
            </Link>{" "}
            aggiornato.
          </p>
        </Modal>
      ) : null}

      {/* {showNewRegulationModal ? (
        <Modal
          show={showNewRegulationModal}
          close={() => closeModalRegulation()}
        >
          <p>
            Gentile utente, ti informiamo che il programma è stato prorogato e i
            punti potranno essere utilizzati fino al 15 luglio 2024. Per
            ulteriori informazioni consulta il{" "}
            <Link
              to={
                process.env.PUBLIC_URL +
                "/pdf/Regolamento_OP_MyLinkPlus_2021-2022-2023-2024.pdf"
              }
              onClick={() => closeModalRegulation()}
            >
              regolamento
            </Link>{" "}
            aggiornato
          </p>
        </Modal>
      ) : null} */}
      <LastLocationProvider>
        <Switch>
          <PrivateRoute
            path="/"
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={Initiative}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/first-access"
            exact
            layoutCmp={AuthLayout}
            isLogged={props.isLogged}
            component={FirstAccess}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/rush-and-win"
            exact
            layoutCmp={AuthLayout}
            isLogged={props.isLogged}
            component={RushAndWin}
            ownLogin={ownLogin}
          />

          <PrivateRoute
            path="/come-funziona"
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={HowToWork}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/area-partner"
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={AreaPartner}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/catalogo"
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={Catalog}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/wishlist"
            exact
            component={Wishlist}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/carrello"
            exact
            component={Cart}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/promo/:promo/convalida"
            exact
            component={FormValidation}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />

          <PrivateRoute
            path="/partecipa"
            exact
            component={WinMore}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/prodotto/:id"
            exact
            component={Product}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />

          <PrivateRoute
            path="/promo/:initiative"
            exact
            component={WinMore}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/sondaggi/:initiative"
            exact
            component={Surveys}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/partner/:id"
            exact
            component={SinglePartner}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/prize/:id"
            exact
            component={PrizeIw}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          {/* <Route
            path="/oauth/:connect"
            exact
            render={(route) => <Oauth {...route} />}
          />
          <Route
            path="/tags/:tag"
            exact
            render={(route) => <BaseLayout cmp={null} {...route} />}
          />
          <Route path="/panel" exact component={Panel} /> */}

          {(!checkIfPastTargetDate() &&
            new Date().getDay() === +process.env.REACT_APP_MONDAY_DAY) ||
          (!checkIfPastTargetDate() &&
            new Date().getDay() === +process.env.REACT_APP_THURSDAY_DAY) ||
          (!checkIfPastTargetDate() && carnevale) ||
          (!checkIfPastTargetDate() && spring) ||
          (!checkIfPastTargetDate() && halloween) ? (
            <PrivateRoute
              path="/promo/:initiative/game/:id"
              exact
              component={Game}
              layoutCmp={BaseLayout}
              isLogged={props.isLogged}
              ownLogin={ownLogin}
              type="gamelayout"
            />
          ) : (
            ""
          )}

          <PrivateRoute
            path="/sondaggi/:initiative/game/:id"
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type="gamelayout"
          />
          <PrivateRoute
            path="/promo/game/:id"
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            isGame={true}
            type="gamelayout"
          />
          <PrivateRoute
            path="/user/profilo"
            exact
            component={Profile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/wishlist"
            exact
            component={Wishlist}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/sondaggi"
            exact
            component={Surveys}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/faq"
            exact
            component={Faq}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/user/awards"
            exact
            component={Awards}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/contatti"
            exact
            component={Contact}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          {userInfo && userInfo.mgm_code && (
            <PrivateRoute
              path="/invite-friend"
              exact
              component={InviteFriend}
              layoutCmp={BaseLayout}
              isLogged={props.isLogged}
              ownLogin={ownLogin}
            />
          )}
          {ownRoutes}
          <Route component={NotFound} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    promoId: state.promo.viewer.id,
    wins: state.user.user.wins,
    isLogged: state.auth.token,
    inGame: state.game.inGameFlow,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
    userInfo: state.user.user.userinfo,
  };
};

export default connect(mapStateToProps)(Routes);
