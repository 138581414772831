import axios from "axios";

export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password) => {
  var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(password);
};

export const getAlias = () => {
  const url = window.location.href;
  const alias = window.location.hostname;
  return alias;
};

export const checkNested = (obj, level, ...rest) => {
  if (obj === undefined) return false;
  if (rest.length == 0 && obj.hasOwnProperty(level)) return true;
  return checkNested(obj[level], ...rest);
};

export const getSlug = () => {
  const url = window.location.href;
  const slug = url.split("/")[4];
  return slug;
};

export const getFirstRoute = () => {
  const url = window.location.href;
  const r = url.split("/")[3];
  return r;
};

export const getOneTimeToken = () => {
  const url = window.location.href;
  const ott = url.split("/")[4];
  return ott;
};

export const getElement = (arr, id) => {
  if (arr !== undefined) {
    if (arr.length !== 0) {
      return arr.find((x) => x.id == id);
    } else {
      return null;
    }
  }
};

export const shuffleArray = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

export const between = (a, b) => {
  var min = Math.min(a, b),
    max = Math.max(a, b);
  return this > min && this < max;
};

export const isBetween = (n, a, b) => {
  return (n - a) * (n - b) <= 0;
};

export const getUnique = (arr, comp) => {
  const unique = arr
    .map((e) => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => arr[e])
    .map((e) => arr[e]);
  return unique;
};

export const throttle = (fn, wait) => {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};

export const getProtocol = () => {
  const url = window.location.href;
  const arr = url.split("/");
  const protocol = arr[0] + "//" + arr[2];
  return protocol;
};

export const deltaDate = (input, days, months, years) => {
  const date = new Date(input);
  date.setDate(date.getDate() + days);
  date.setMonth(date.getMonth() + months);
  date.setFullYear(date.getFullYear() + years);
  return date;
};

export function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const customRegexValidation = (regex, value) => {
  var re = new RegExp(regex);
  return re.test(value);
};

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getDistricts = () => {
  let endpoint = process.env.REACT_APP_API_URL + "utility/districts/it";
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint)
      .then((res) => {
        if (res.status === 200) {
          //console.log("RES ", res);
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCities = (value) => {
  let endpoint = process.env.REACT_APP_API_URL + "utility/cities/" + value;
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint)
      .then((res) => {
        if (res.status === 200) {
          //console.log("RES ", res);
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const isWinExpires = (date) => {
  const today = new Date();
  const maxDate = new Date(date);
  maxDate.setDate(maxDate.getDate() + 7);

  return Date.parse(today) > Date.parse(maxDate);
};
