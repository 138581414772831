import React from "react";
import styled, { css } from "styled-components";
import useSpring from "../../hooks/useSpring";
import { respondTo } from "../../theme/mixin";

const BoxWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  box-shadow: 0px 5px 15px #00000026;
  padding: 15px;
  background-color: ${(props) => (props.reverse ? props.color : "#fff")};

  border-radius: 25px;
  ${({ spring }) =>
    spring &&
    css`
      background-image: url(${process.env.PUBLIC_URL + "/images/spring.svg"});
      background-repeat: no-repeat;
      background-position: bottom -70% right -25%;
      background-size: 55% auto;
      ${respondTo.sm`
    background-size: auto;
  `}
      ${respondTo.md`
    background-size: 55% auto;
  `}
    `}
  ${({ natale }) =>
    natale &&
    css`
      background-image: url(${process.env.PUBLIC_URL +
      "/images/vinciImagesNatale.svg"});
      background-repeat: no-repeat;
      background-position: bottom 35px right 15px;
    `}

  ${({ rushandwin }) =>
    rushandwin &&
    css`
      background-image: url(${process.env.PUBLIC_URL +
      "/images/background-orange.png"});
      background-repeat: no-repeat;
      background-position: bottom 0 right 0px;
      background-size: contain;
    `}
    ${({ carnevale }) =>
    carnevale &&
    css`
      background-image: url(${process.env.PUBLIC_URL +
      "/images/carnevale.png"});
      background-repeat: no-repeat;
      background-position: top 80px right 10px;
      background-size: 45% auto;
      @media (min-width: 768px) and (max-width: 1023px) {
        background-position: top 80px right 200px;
      }
      ${respondTo.sm`
    background-size: auto;

  `}
      ${respondTo.md`
    background-size: 45% auto;

  `}
    `}

  .wrapper {
    position: relative;
    padding-left: ${(props) => (props.position === "right" ? 0 : "25px")};
    padding-right: ${(props) => (props.position === "right" ? "25px" : 0)};
    padding: ${(props) => props.reverse && 0};
    flex-grow: 1;
    height: 100%;
    .box-body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    span {
      color: #ff7db9;
      font-size: 18px;
      font-weight: 300;
      &.spring-text-code {
        color: #e4e7f1;
        font-size: 16px;
      }
      &.spring-text-code-number {
        color: #adb1bc;
        font-size: 16px;
        font-weight: bold;
      }
      &.spansmall {
        color: rgb(235, 90, 63);
        font-weight: 700;
        font-size: 12px;
      }
    }

    &:before {
      position: absolute;
      top: 0;
      left: ${(props) => (props.position === "right" ? "auto" : 0)};
      right: ${(props) => (props.position === "right" ? 0 : "auto")};
      content: "";
      display: block;
      width: 10px;
      height: 100%;
      background-color: ${(props) => props.color};
      border-radius: 10px;
      display: ${(props) => props.reverse && "none"};
    }

    .box-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 30px;

      h5 {
        font-weight: 700;
        font-size: 21px;
        color: ${(props) => props.color};
        margin-right: 5px;
        margin-top: 30px;
      }
    }
  }

  .box-subtitle {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 20px -15px;

    h5 {
      font-weight: 700;
      font-size: 21px;
      color: ${(props) => (props.reverse ? "#fff" : props.color)};
      margin-right: 5px;
      padding-left: ${(props) => (props.reverse ? "15px" : "35px")};
    }

    .box-second-title {
      width: 100%;
      background-color: ${(props) => (props.reverse ? "#fff" : props.color)};
      color: ${(props) => (props.reverse ? props.color : "#fff")};
      font-size: 18px;
      font-weight: 300;
      padding: 5px 0 5px ${(props) => (props.reverse ? "15px" : "35px")};
      margin-top: 3px;
    }
  }
`;

const ContentBorderBox = (props) => {
  return (
    <BoxWrapper
      color={props.color}
      position={props.position}
      style={props.style}
      reverse={props.reverse}
      fullheight={props.fullheight}
      className={props.className}
      spring={props.spring}
      natale={props.natale}
      rushandwin={props.rushandwin}
      carnevale={props.carnevale}
    >
      {props.subtitle && (
        <div className="box-subtitle">
          <h5>{props.title}</h5>
          {props.icon && <img src={props.icon} alt="" />}
          <div className="box-second-title">{props.subtitle}</div>
        </div>
      )}
      <div className="wrapper">
        {props.title && !props.subtitle && (
          <div className="box-header">
            <h5>{props.title}</h5>
            {props.icon && <img src={props.icon} alt="" />}
          </div>
        )}
        <div className="box-body">{props.children}</div>
      </div>
    </BoxWrapper>
  );
};

export default ContentBorderBox;
