import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect, useSelector } from "react-redux";
import { getSlug } from "../../utils/utils";
import { withRouter, Link } from "react-router-dom";
import * as actionCreators from "../../store/actions";
import { respondTo } from "../../theme/mixin";
import { Viewer, Text } from "../../components/Ui";
import Avatar from "../../components/Avatar/Avatar";
import useNatale from "../../hooks/useNatale";
import useCarnevale from "../../hooks/useCarnevale";
import moment from "moment";
import NotFound from "../NotFound";
const WinMoreWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;
    ${respondTo.md`
      padding: 0;
    `}
    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;
      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 270px;
        height: 100%;
        margin-top: 0;
      `}
      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }
  .asterisk {
    font-size: 10px;
    color: #adb1bc;
    margin-top: 20px;
    a {
      color: #eb5a3f;
      text-decoration: underline;
      font-weight: bold;
      margin: 0 2px;
    }
  }
  .ramaining-attempts {
    margin-top: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .ramaining-info {
      margin-left: 10px;
    }
  }
`;

const WinMore = (props) => {
  const {
    appConfig: {
      settings: { multipromo },
    },
    isLogged,
    logo,
    promoinfo,
    viewer,
    getGames,
    games,
    oauths,
    ownLogin,
    oauthInfo,
    location: { pathname },
  } = props;

  let slug = "vinciancoradipiu";

  /*   if (multipromo) {
    slug = getSlug();
  } */

  //let idFirstGame = null;
  let idSecondGame = null;

  const [idFirstGame, setIdFirstGame] = useState(null);
  const currentUser = useSelector((state) => state.user.user.userinfo);
  const { title, content } = useSelector(
    (state) => state.cms?.vinciAncoraDiPiu?.[0]?.contents?.[0]?.i18l || {}
  );
  const natale = useNatale();
  const carnevale = useCarnevale();
  useEffect(() => {
    const d = new Date();

    if (d.getDay() === +process.env.REACT_APP_MONDAY_DAY) {
      if (games.length > 0) {
        games.find((item) => +item.id === +process.env.REACT_APP_GAME_MONDAY)
          ? setIdFirstGame(+process.env.REACT_APP_GAME_MONDAY)
          : setIdFirstGame(null);
      }
    } else if (d.getDay() === +process.env.REACT_APP_THURSDAY_DAY) {
      if (games.length > 0) {
        games.find((item) => +item.id === +process.env.REACT_APP_GAME_THURSDAY)
          ? setIdFirstGame(+process.env.REACT_APP_GAME_THURSDAY)
          : setIdFirstGame(null);
      }
    } else {
      setIdFirstGame(null);
    }
  }, [games]);

  /*   if (games.length > 0) {
    idSecondGame = games[1].id;
  } */

  useEffect(() => {
    getGames(slug, isLogged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);
  const checkIfPastTargetDate = () => {
    const targetDate = moment("2024-06-28T00:00:00");
    const now = moment();

    return now.isAfter(targetDate);
  };
  return (
    <>
      {!checkIfPastTargetDate() ? (
        <WinMoreWrapper>
          <div className="slide">
            <img
              src={process.env.PUBLIC_URL + "/images/vinci-di-piu-slide.jpg"}
              alt=""
            />
            <div className="content">
              <div>
                <Text as="h3">
                  Vinci ancora di{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                  >
                    <path
                      id="Tracciato_2"
                      data-name="Tracciato 2"
                      d="M710.358,136.294a2.625,2.625,0,0,0-1.525.507,1.813,1.813,0,0,1-1.068.362h-2.8a.656.656,0,0,1-.656-.656v-2.8a1.729,1.729,0,0,1,.341-1.038,2.644,2.644,0,0,0-2.2-4.228,2.676,2.676,0,0,0-2.569,2.674,2.622,2.622,0,0,0,.507,1.525,1.815,1.815,0,0,1,.362,1.068v2.8a.656.656,0,0,1-.656.656h-2.8a1.726,1.726,0,0,1-1.038-.341,2.643,2.643,0,0,0-4.228,2.2,2.676,2.676,0,0,0,2.674,2.569,2.624,2.624,0,0,0,1.525-.507,1.815,1.815,0,0,1,1.068-.362h2.8a.656.656,0,0,1,.656.656v2.8a1.728,1.728,0,0,1-.341,1.038,2.644,2.644,0,0,0,2.2,4.227,2.676,2.676,0,0,0,2.569-2.674,2.626,2.626,0,0,0-.507-1.525,1.813,1.813,0,0,1-.362-1.068v-2.8a.656.656,0,0,1,.656-.656h2.8a1.727,1.727,0,0,1,1.038.341,2.643,2.643,0,0,0,4.227-2.2A2.676,2.676,0,0,0,710.358,136.294Z"
                      transform="translate(-692.033 -128.44)"
                      fill="#6cb967"
                    />
                  </svg>
                </Text>
                <Text>{content}</Text>
                {currentUser && (
                  <div className="ramaining-attempts">
                    <Avatar shadow />
                    <div className="ramaining-info">
                      {currentUser.firstname}, hai
                      <br />
                      <strong>
                        {currentUser.max_attempts === null
                          ? 0
                          : currentUser.max_attempts}{" "}
                        possibilità di gioco
                      </strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Viewer
            scream={promoinfo.i18l ? promoinfo.i18l.title : ""}
            description={promoinfo.i18l ? promoinfo.i18l.description : ""}
            imgdesktop={viewer.img_desktop}
            imgmobile={viewer.img_mobile}
            logopromo={viewer.logo}
            islogged={isLogged}
            logo={logo}
            url={viewer.i18l ? viewer.i18l.url : null}
            cta={viewer.i18l ? viewer.i18l.calltoaction : null}
            ctatype={viewer.calltoaction_type}
            iscta={viewer.calltoaction}
            dark_text={viewer.dark_text}
            oauths={oauths}
            ownlogin={ownLogin}
            oauthinfo={oauthInfo}
            idFirstGame={idFirstGame}
            pathname={pathname}
            multipromo={multipromo}
          />
          <div className="asterisk">
            {carnevale && (
              <div>
                La pentolaccia: concorso valido dal 09/02/2024 al 13/02/2024.
                Valore montepremi € 1.750,00 iva non esposta. Regolamento{" "}
                <Link
                  to={{
                    pathname:
                      process.env.PUBLIC_URL +
                      "/pdf/2024 TISCALI - LA PENTOLACCIA co instant win 19.01.2024 DEF.pdf",
                  }}
                  target="_blank"
                >
                  qui
                </Link>
                <br />
                <br />
              </div>
            )}
            Vinci (ancora) di +: concorso a premi valido complessivamente dal
            17/07/23 al 27/06/24. Prima estrazione entro il 15/09/23 ed
            estrazione finale entro il 09/07/24. Montepremi 56.127,15 IVA
            inclusa ove presente. Regolamento disponibile{" "}
            <Link
              to={{
                pathname:
                  process.env.PUBLIC_URL +
                  "/pdf/Regolamento_Linkem_Vinci_ancora_di_più.pdf",
              }}
              target="_blank"
            >
              quinatale
            </Link>
            <br /> *Restrizioni applicate. Vedere dettagli su:{" "}
            <a
              href="https://www.amazon.it/gc-legal"
              target="_blank"
              rel="noopener noreferrer"
            >
              amazon.it/gc-legal
            </a>
            . <br />
            {natale && (
              <div>
                <br />A Christmas Game: concorso a premi valido complessivamente
                dal 13/12/2023 al 19/12/2023. L’estrazione finale, la
                verbalizzazione dei vincitori Instant Win e l’eventuale
                estrazione a recupero avverranno entro il 31/01/2024. Montepremi
                complessivo Euro 8.500,00 iva non esposta. Regolamento
                disponibile{" "}
                <Link
                  to={{
                    pathname:
                      process.env.PUBLIC_URL +
                      "/pdf/regulation_natale_2023.pdf",
                  }}
                  target="_blank"
                >
                  qui
                </Link>
                <br /> *Restrizioni applicate. Vedere dettagli su:{" "}
                <a
                  href="https://www.amazon.it/gc-legal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  amazon.it/gc-legal
                </a>
                .
              </div>
            )}
          </div>
        </WinMoreWrapper>
      ) : (
        <NotFound msg="Promozione non attiva" />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user.userinfo,
    partners: state.partner.partnerList,
    wishlist: state.user.user.wishlist,
    appConfig: state.app.config,
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    loadingviewer: state.promo.loadingviewer,
    promoinfo: state.promo.promoinfo,
    promoinfoloading: state.promo.checkLoading,
    viewer: state.promo.viewer,
    errorviewer: state.promo.errorviewer,
    intro: state.cms.intro,
    loadingintro: state.cms.loadingintro,
    errorintro: state.cms.errorintro,
    games: state.game.games,
    gamesError: state.game.error,
    loadingGames: state.game.loadingGames,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    oauthInfo: state.app.oauthInfo,
    error404: state.promo.error404,
    products: state.products.allProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGames: (slug, isLogged) =>
      dispatch(actionCreators.getGames(slug, isLogged)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WinMore)
);
