import React, { useEffect, useState } from "react";
import { CustomContainer } from "../../components/Ui";
import { connect, useSelector, useDispatch } from "react-redux";
import { getSlug } from "../../utils/utils";
import Seo from "../../components/Seo";
import NotFound from "../NotFound";
import * as actionCreators from "../../store/actions";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import { Button } from "../../components/Ui";
import Avatar from "../../components/Avatar/Avatar";
import HomeSlider from "../../components/HomeSlider/HomeSlider";
import Premiati from "./Premiati/Premiati";
import ContentBorderBox from "../../components/ContentBorderBox/ContentBorderBox";
import PartnerBox from "../../components/PartnerBox/PartnerBox";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import useSpring from "../../hooks/useSpring";
import useNatale from "../../hooks/useNatale";
import moment from "moment";
import useCarnevale from "../../hooks/useCarnevale";
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  padding: 0 15px;
  align-content: stretch;

  ${respondTo.md`
    padding: 0;
  `}

  .col33 {
    width: 100%;
    margin: 15px 0;

    ${respondTo.md`
      margin: 0;
      width: calc(33.333% - 15px);
    `}
  }

  .col66 {
    width: 100%;
    margin: 15px 0;

    ${respondTo.md`
      margin: 0;
      width: calc(66.666% - 15px);
    `}
  }

  .col100 {
    width: 100%;
    margin: 15px 0;
  }

  .vinciBox {
    display: flex;
    flex-direction: column;
    height: 100%;

    p {
      color: #fff;
      font-size: 18px;
      font-weight: 300;
      //margin-bottom: 10px;
    }

    span {
      display: block;

      color: #3a3c42;
      font-size: 14px;
    }
    .vinciBox_flex {
      display: flex;
      gap: 10px;
    }
    .vinciBox_img {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (min-width: 992px) {
        width: 52%;
      }
    }
    .restrizioni-asterisco {
      font: normal normal normal 12px Mont;
      font-weight: 200;
      margin-left: 20%;
    }

    .restrizioni-asterisco,
    .restrizioni-asterisco a {
      text-align: left;

      letter-spacing: 0px;
      color: #ffffff;
    }

    .restrizioni-asterisco a {
      font: normal normal bold 12px Mont;
    }
    button {
      color: #6cb967;
      position: relative;
      width: 150px;
      @media (min-width: 992px) {
        width: auto;
      }
      @media (min-width: 1024px) {
        width: 150px;
      }
    }
  }

  .vinciBox {
    p:nth-child(1) {
      color: #fff;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 15px;
    }

    p:nth-child(2) {
      margin-bottom: 5px;
    }

    span {
      color: #3a3c42;
      font-size: 14px;
    }
    a {
      margin-top: auto;
    }
    button {
      color: #6cb967;
      position: relative;
    }

    .ramaining-attempts {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .ramaining-info {
        color: #fff;
        margin-left: 10px;
      }
    }
  }

  .conosciamociBox {
    .firstRow {
      margin-top: 40px;
      display: flex;

      & > div {
        padding-right: 20px;
      }

      img {
        width: 100%;
      }

      .conosciamociTitle {
        background-color: #fff;
        color: #00abe5;
        font-size: 12px;
        margin-top: 10px;
        text-transform: uppercase;
        border-radius: 10px;
        padding: 7px 10px;
        font-weight: 700;
        text-align: center;
        width: 90%;
        margin-left: 5%;
      }

      .conosciamociStatus {
        color: #fff;
        opacity: 0.5;
        font-size: 14px;
        text-align: center;
        display: block;
        margin-top: 5px;
      }

      p {
        width: 50%;
        color: #fff;
        font-size: 18px;
        font-weight: 300;

        span {
          font-size: 14px;
          display: block;
          color: #fff;
          margin-top: 20px;
        }
      }
    }

    .secondRow {
      margin-top: 50px;

      .badges {
        display: flex;
        justify-content: space-between;

        .badge {
          display: flex;
          align-items: center;

          strong {
            flex: 0 0 45px;
            height: 45px;
            background-color: #038ab8;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            font-size: 16px;
            color: #fff;
          }

          span {
            color: #66d5fb;
            font-size: 14px;
            font-weight: 300;
          }
        }
      }

      .badge-links {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        strong {
          text-transform: uppercase;
          color: #fff;
          font-size: 16px;
        }

        button {
          color: #00abe5;
        }
      }
    }
  }
`;

const PartnersWrap = styled.div`
  .partners-list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;

    ${respondTo.md`
      flex-wrap: nowrap;
    `}

    .partners-box {
      width: 100%;
      margin-bottom: 15px;

      ${respondTo.md`
        width: calc(33.333% - 25px);
        margin-bottom: 0;
      `}
    }
  }

  .partners-box {
    padding: 0 15px;
  }

  .partners-footer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    & > div {
      margin-bottom: 20px;

      ${respondTo.md`
        margin-bottom: 0;
      `}
    }

    h4 {
      color: #fdca46;
      font-size: 28px;
      margin-bottom: 10px;
    }
  }
`;
const FlexBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  margin-top: 20px;
  p {
    color: #fff;
    font-size: 8px !important;
    a {
      color: inherit;
      font-weight: bold;
      font-size: 8px !important;
      text-decoration: underline;
    }
  }
`;

const Asterisk = styled.small`
  font-size: 10px;
  color: #adb1bc;
  display: block;
  margin-top: 20px;
  line-height: 1.5;
  padding: 0 15px;
  z-index: 1;

  ${respondTo.md`
    padding: 0;
  `}

  a {
    color: #eb5a3f;
    text-decoration: underline;
  }
`;

const Initiative = (props) => {
  const [isValid, setIsValid] = useState(true);
  const [showPromo, setShowPromo] = useState(false);
  const showSpring = useSpring();
  const showNatale = useNatale();
  const showcarnevale = useCarnevale();
  //check date now and date change game images
  let dataChangeGame = moment("2024-03-28T00:00:00");
  let now = moment();
  let newVinciPrize = false;
  if (now.isBefore(dataChangeGame)) {
  } else if (now.isAfter(dataChangeGame)) {
    newVinciPrize = true;
  } else {
    newVinciPrize = true;
  }

  const {
    appConfig: {
      settings: { multipromo },
    },
    products,
    isLogged,
    logo,
    promoinfo,
    getPromoViewer,
    loadingviewer,
    viewer,
    errorviewer,
    getCmsIntro,
    intro,
    loadingintro,
    errorintro,
    getGames,
    games,
    gamesError,
    loadingGames,
    oauths,
    ownLogin,
    oauthInfo,
    getPromoInfo,
    error404,
    partners,
    location: { pathname },
    wishlist,
    userInfo,
    highlights,
  } = props;

  let slug = "get";

  if (multipromo) {
    slug = getSlug();
  }
  const currentUser = useSelector((state) => state.user.user.userinfo);
  useEffect(() => {
    getGames("promo-survey", isLogged);
  }, [isLogged]);

  let seo = null;

  if (Object.entries(promoinfo).length !== 0) {
    const {
      i18l: {
        seo: { title, description, keywords },
      },
    } = promoinfo;
    seo = <Seo title={title} description={description} keywords={keywords} />;
  }

  useEffect(() => {
    setIsValid(true);
    setShowPromo(true);

    localStorage.setItem("lasturl", window.location.href);
  }, []);

  useEffect(() => {
    if (error404.length === 1) {
      setIsValid(false);
    }
  }, [error404]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  let content = null;

  const options = {
    dots: false,
    autoplay: false,
    slidesToShow: 2,
    centerMode: true,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const dispatch = useDispatch();

  const gameSpring = useSelector((state) => state?.promo?.game?.[0]);
  const gamesActive = useSelector((state) => state?.game?.games);

  const [isGamesActiveSpringReady, setIsGamesActiveSpringReady] =
    useState(false);

  const gamesActiveSpring = gamesActive?.filter(
    (game) => +game.id === +process.env.REACT_APP_GAME_SPRING
  );

  useEffect(() => {
    if (gamesActiveSpring?.length > 0) {
      setIsGamesActiveSpringReady(true);
    }
  }, [gamesActiveSpring]);

  useEffect(() => {
    dispatch(actionCreators.getGames("vinciancoradipiu", isLogged));

    if (showSpring && isGamesActiveSpringReady) {
      dispatch(
        actionCreators.getGame(
          process.env.REACT_APP_GAME_SPRING,
          "vinciancoradipiu",
          ""
        )
      );
    }
  }, [dispatch, showSpring, isGamesActiveSpringReady, isLogged]);

  const remaining_attempts =
    showSpring && +gameSpring?.id === +process.env.REACT_APP_GAME_SPRING
      ? gameSpring?.remaining_attempts
      : 0;
  const checkIfPastTargetDate = () => {
    const targetDate = moment("2024-06-28T00:00:00");
    const now = moment();
    return now.isAfter(targetDate);
  };
  content = (
    <React.Fragment>
      <HomeSlider />
      {seo}

      <Row>
        <div className="col33">
          <ContentBorderBox
            title="Conosciamoci di "
            subtitle="Rispondi e accumula punti"
            icon={process.env.PUBLIC_URL + "/icons/plus-white-icon.svg"}
            color="#00ABE5"
            reverse
            fullheight
          >
            <div className="conosciamociBox">
              <div className="firstRow">
                <div>
                  {games.length > 0 && games.slice(-1)[0] && (
                    <>
                      <img src={games.slice(-1)[0].main_image} alt="" />
                      <div className="conosciamociTitle">
                        {games.slice(-1)[0].i18l.info.title}
                      </div>
                      <span className="conosciamociStatus">
                        {games.slice(-1)[0].log.playslog
                          ? "Completato"
                          : "Da iniziare"}
                      </span>
                    </>
                  )}
                </div>
                <p>
                  Hai già partecipato ai nostri sondaggi? Rispondi subito alle
                  domande e guadagna punti
                  <span>
                    <strong>10 Pt </strong>
                    in palio
                  </span>
                </p>
              </div>
              <div className="secondRow">
                <div className="badges">
                  <div className="badge">
                    <strong>
                      {
                        games.filter((game) => game.log.playslog !== null)
                          .length
                      }
                    </strong>
                    <span>badge completati</span>
                  </div>
                  <div className="badge">
                    <strong>
                      {
                        games.filter((game) => game.log.playslog === null)
                          .length
                      }
                    </strong>
                    <span>badge da completare</span>
                  </div>
                </div>

                <div className="badge-links">
                  <Link to="/sondaggi/promo-survey">
                    <strong>
                      Guarda tutti <ChevronRight size={30} />
                    </strong>
                  </Link>

                  {games.length > 0 &&
                    games.slice(-1)[0] &&
                    !games.slice(-1)[0].log.playslog && (
                      <Link
                        to={`/sondaggi/promo-survey/game/${
                          games.slice(-1)[0].id
                        }`}
                      >
                        <Button
                          upper
                          gradient={{ left: "#7DDEFF", right: "#fff" }}
                          hoverGradient={{ left: "#fff", right: "#fff" }}
                        >
                          Rispondi
                          <ChevronRight size={30} />
                        </Button>
                      </Link>
                    )}
                </div>
              </div>
            </div>
          </ContentBorderBox>
        </div>

        <div className="col66">
          <Premiati
            products={products.slice(0, 3)}
            wishlist={wishlist}
            userInfo={userInfo}
          />
        </div>
      </Row>

      <Row>
        <div className={checkIfPastTargetDate() ? "col100" : "col66"}>
          <ContentBorderBox
            title="Club"
            subtitle="Scopri vantaggi e sconti esclusivi"
            icon={process.env.PUBLIC_URL + "/icons/club-icon.svg"}
            color="#FDCA46"
          >
            <PartnersWrap>
              <Slider {...options}>
                {partners
                  .filter((p) => p.vetrina === 1)
                  .map((partner, i) => (
                    <div className="partners-box" key={i}>
                      <PartnerBox partner={partner} />
                    </div>
                  ))}
              </Slider>
              <div className="partners-footer">
                <div>
                  <h4>
                    Non perdere le promozioni
                    <br /> dei nostri partner
                  </h4>
                  <p>
                    Segui <strong>le tue passioni</strong> grazie agli{" "}
                    <strong>sconti esclusivi</strong> dei nostri partner.
                    <br />
                    Non hai bisogno di accumulare punti, scegli subito l’offerta
                    giusta per te!
                  </p>
                </div>
                <Link to="/area-partner">
                  <Button
                    upper
                    gradient={{ left: "#FDCA46", right: "#FFDF8D" }}
                    hoverGradient={{ left: "#FFDF8D", right: "#FFDF8D" }}
                  >
                    Vai al club
                    <ChevronRight size={30} />
                  </Button>
                </Link>
              </div>
            </PartnersWrap>
          </ContentBorderBox>
        </div>
        {!checkIfPastTargetDate() ? (
          <div className="col33">
            <ContentBorderBox
              title="Vinci ancora di "
              subtitle="Partecipa ai concorsi"
              icon={process.env.PUBLIC_URL + "/icons/plus-white-icon.svg"}
              color="#6CB967"
              reverse
              fullheight
              style={{ display: "flex", flexDirection: "column" }}
              spring={showSpring}
              natale={showNatale}
              carnevale={showcarnevale}
            >
              <div className="vinciBox">
                {currentUser && (
                  <div className="ramaining-attempts">
                    <Avatar shadow />
                    <div className="ramaining-info">
                      <div>
                        {currentUser.firstname}
                        {showSpring ? (
                          <>
                            , hai <br />
                            <strong>
                              {remaining_attempts === null
                                ? 0
                                : remaining_attempts}{" "}
                              tentativi
                            </strong>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <p>
                      <strong>
                        {remaining_attempts === null ? 0 : remaining_attempts}{" "}
                        possibilità di gioco
                      </strong>
                    </p>  */}
                    </div>
                  </div>
                )}
                <div className="vinciBox_flex">
                  <div style={{ width: "50%" }}>
                    {showcarnevale ? (
                      <p style={{ width: "130%" }}>La pentolaccia</p>
                    ) : showSpring ? (
                      <p>Festeggia la primavera con noi!</p>
                    ) : showNatale ? (
                      <p>A Christmas Game</p>
                    ) : (
                      <p>Vinci ancora di +</p>
                    )}
                    {showSpring ? (
                      <>
                        <span style={{ width: "100%" }}>
                          <strong>Dal 27 al 30 maggio,</strong> partecipa al
                          nostro concorso «Fai volare l'aquilone» e prova a
                          vincere fantastici premi.
                          <br />
                          Ogni giorno in palio per te{" "}
                          <strong>due Cuffie Wireless Sony</strong>.
                        </span>
                      </>
                    ) : showNatale ? (
                      <>
                        <span style={{ width: "100%" }}>
                          <strong>Dal 13 al 19 dicembre</strong>
                          <br />
                          partecipa al nostro concorso natalizio a tema Mercante
                          in Fiera e scopri subito se le due carte hanno lo
                          stesso simbolo: <strong>ogni giorno</strong> in palio
                          per te un <strong>fantastico premio.</strong>
                        </span>
                      </>
                    ) : showcarnevale ? (
                      <>
                        <span style={{ width: "100%" }}>
                          <strong>Dal 9 al 13 febbraio,</strong>
                          <br /> partecipa al nostro
                          <br /> concorso di Carnevale
                          <br /> e scopri cosa ti riserva.
                          <br />
                          <strong>Ogni giorno </strong>in palio per
                          <br /> te una{" "}
                          <strong>
                            Gift Card <br /> digitale Volagratis del
                            <br /> valore di 350€.
                          </strong>
                        </span>
                      </>
                    ) : (
                      <span>
                        Partecipa al nostro concorso e tenta la fortuna ogni
                        lunedì e giovedì. <br /> Prova a vincere ogni lunedì un
                        <strong> Buono Regalo Amazon.it* da 150€</strong> e ogni
                        giovedì{" "}
                        {newVinciPrize ? (
                          <span>
                            un paio di
                            <strong> Cuffie on ear BOSE</strong>.
                          </span>
                        ) : (
                          <span>
                            un
                            <strong> Microonde SAGE</strong>.
                          </span>
                        )}
                      </span>
                    )}
                    {true && (
                      <FlexBtn>
                        <Link to={`/promo/vinciancoradipiu`}>
                          <Button
                            upper
                            gradient={{ left: "#fff", right: "#fff" }}
                          >
                            Gioca <ChevronRight size={30} />
                          </Button>
                        </Link>
                      </FlexBtn>
                    )}
                  </div>

                  {!showSpring && !showNatale && !showcarnevale && (
                    <div className="vinciBox_img">
                      <img
                        src={
                          newVinciPrize
                            ? process.env.PUBLIC_URL +
                              "/images/vinciImagesOther.png"
                            : process.env.PUBLIC_URL + "/images/vinciImages.png"
                        }
                        alt=""
                      />
                      <div className="restrizioni-asterisco">
                        *Restrizioni applicate. Vedere dettagli su:{" "}
                        <a
                          href="https://www.amazon.it/gc-legal"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          amazon.it/gc-legal
                        </a>
                        .{" "}
                      </div>
                    </div>
                  )}
                </div>

                {/* {highlights[0] && (  */}
              </div>
            </ContentBorderBox>
          </div>
        ) : (
          ""
        )}
      </Row>

      {/* <Asterisk>
        *Concorso valido dal 06/07/21 al 30/05/22 con estrazione entro il
        16/06/22. Montepremi complessivo € 57.723,00 iva inclusa. Per maggiori
        informazioni, periodi di partecipazione, premi e dettagli, consulta il{" "}
        <Link
          to={{
            pathname:
              process.env.PUBLIC_URL +
              "/pdf/Regolamento_OP_MyLinkPlus_2021-2022-2023-2024.pdf",
          }}
          target="_blank"
        >
          regolamento
        </Link>
        .
      </Asterisk> */}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {isValid ? (
        showPromo ? (
          <CustomContainer>{content}</CustomContainer>
        ) : (
          <CustomContainer></CustomContainer>
        )
      ) : (
        <NotFound internal type="promo" />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.user.userinfo,
    partners: state.partner.partnerList,
    wishlist: state.user.user.wishlist,
    appConfig: state.app.config,
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    loadingviewer: state.promo.loadingviewer,
    promoinfo: state.promo.promoinfo,
    promoinfoloading: state.promo.checkLoading,
    viewer: state.promo.viewer,
    errorviewer: state.promo.errorviewer,
    intro: state.cms.intro,
    loadingintro: state.cms.loadingintro,
    errorintro: state.cms.errorintro,
    games: state.game.games,
    gamesError: state.game.error,
    loadingGames: state.game.loadingGames,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    oauthInfo: state.app.oauthInfo,
    error404: state.promo.error404,
    products: state.products.allProducts,
    highlights: state.promo.hgpromo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCmsIntro: (slug) => dispatch(actionCreators.getCmsIntro(slug)),
    getGames: (slug, isLogged) =>
      dispatch(actionCreators.getGames(slug, isLogged)),
    getPromoInfo: (slug, cid) =>
      dispatch(actionCreators.getPromoInfo(slug, cid)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Initiative)
);
