import * as actionTypes from "./actionTypes";

import customaxios from "../../config/axios-refresh-token";
import customAxiosNm from "../../config/axios-refresh-token-no-multipart";

import { sendLogError } from "./error";
import { getUserInfo } from "./user";

export const gameFlowStart = () => {
  return {
    type: actionTypes.GAME_FLOW_START,
  };
};

export const gameFlowEnd = () => {
  return {
    type: actionTypes.GAME_FLOW_END,
  };
};

const getCodeStart = () => {
  return {
    type: actionTypes.GET_CODE_START,
  };
};

const getCodeSuccess = (code) => {
  return {
    type: actionTypes.GET_CODE_SUCCESS,
    code,
  };
};

const getCodeError = (error) => {
  return {
    type: actionTypes.GET_CODE_ERROR,
    error,
  };
};

export const getCodeCleanError = (id) => {
  return {
    type: actionTypes.GET_CODE_CLEAN_ERROR,
    id,
  };
};

const cleanCodeData = (id) => {
  return {
    type: actionTypes.CLEAN_CODE_DATA,
    id,
  };
};

export const cleanCodeFunction = (id) => {
  return (dispatch) => {
    dispatch(cleanCodeData(id));
  };
};

export const getCode = (id, slug, section, resultData, sid) => {
  let endpoint = `game/${slug}/${id}/getcode`;
  return (dispatch) => {
    dispatch(getCodeStart());
    customaxios
      .post(endpoint, {
        data: JSON.stringify(resultData),
        section,
        sid,
      })
      .then((result) => {
        if (result.status === 200) {
          if (result.data.error) {
            //forse devo creare errore ad hoc perche altrimenti anche i 500 o 400 compaiono in modale
            let err = {
              id,
              result: result.data.result,
              status: result.data.error,
            };
            //dispatch(getCodeError(err));
            dispatch(getCodeSuccess(result.data));
          } else {
            dispatch(getCodeSuccess(result.data));
            // if (gc) {
            // 	dispatch(getCodeAfterGameSuccess());
            // }
          }
        }
      })
      .catch((error) => {
        // DEVO PASSARE OGGETTO ID, ERROR CONTROLLARE I 400
        dispatch(getCodeError(error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getLinkStart = () => {
  return {
    type: actionTypes.GET_LINK_START,
  };
};

const getLinkSuccess = (link) => {
  return {
    type: actionTypes.GET_LINK_SUCCESS,
    link,
  };
};

const getLinkError = (error) => {
  return {
    type: actionTypes.GET_LINK_ERROR,
    error,
  };
};

export const getLink = (id) => {
  let endpoint = `game/${id}/getlink`;
  return (dispatch) => {
    dispatch(getLinkStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(getLinkSuccess(result.data));
      })
      .catch((error) => {
        dispatch(getLinkError(error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const sendGameResultStart = () => {
  return {
    type: actionTypes.SEND_GAME_RESULT_START,
  };
};

const sendGameResultSuccess = (result) => {
  return {
    type: actionTypes.SEND_GAME_RESULT_SUCCESS,
    result,
  };
};

const sendGameResultError = (error) => {
  return {
    type: actionTypes.SEND_GAME_RESULT_ERROR,
    error,
  };
};

export const sendGameResult = (id, result) => {
  let endpoint = `game/${id}/result`;
  return (dispatch) => {
    dispatch(sendGameResultStart());
    return customaxios
      .post(endpoint, { result })
      .then((result) => {
        dispatch(sendGameResultSuccess());
      })
      .catch((error) => {
        dispatch(sendGameResultError());
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getCodeAfterGameStart = () => {
  return {
    type: actionTypes.GET_CODE_AFTER_GAME_START,
  };
};

const getCodeAfterGameSuccess = () => {
  return {
    type: actionTypes.GET_CODE_AFTER_GAME_SUCCESS,
  };
};

const getCodeAfterGameError = (error) => {
  return {
    type: actionTypes.GET_CODE_AFTER_GAME_ERROR,
    error,
  };
};

export const getCodeAfterGame = (id, result, gc) => {
  return (dispatch) => {
    dispatch(getCodeAfterGameStart());

    return dispatch(sendGameResult(id, result))
      .then(() => {
        if (gc) {
          dispatch(getCode(id, gc));
        } else {
          dispatch(getCodeAfterGameSuccess());
        }
      })

      .catch((error) => {
        dispatch(getCodeAfterGameError(error));
        if (error.response.status >= 500) {
          // dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getIwStart = () => {
  return {
    type: actionTypes.GET_IW_START,
  };
};

const getIwSuccess = (iw) => {
  return {
    type: actionTypes.GET_IW_SUCCESS,
    iw,
  };
};

const getIwError = (error) => {
  return {
    type: actionTypes.GET_IW_ERROR,
    error,
  };
};

export const getIwCleanError = () => {
  return {
    type: actionTypes.GET_IW_CLEAN_ERROR,
  };
};

const cleanIwData = (id) => {
  return {
    type: actionTypes.CLEAN_IW_DATA,
    id,
  };
};

export const cleanIwDataFunction = (id) => {
  return (dispatch) => {
    dispatch(cleanIwData(id));
  };
};

export const getIw = (id, slug, section, resultData, sid, prizes) => {
  let endpoint = `game/${slug}/${id}/instantwin`;
  return (dispatch) => {
    dispatch(getIwStart());
    customAxiosNm
      .post(endpoint, {
        data: JSON.stringify(resultData),
        section,
        sid,
        prizebag: prizes,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(getIwSuccess(response.data));
          dispatch(getUserInfo());
          dispatch(decreaseRemainingAttempts());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(getIwError(error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const decreaseRemainingAttempts = () => {
  return {
    type: actionTypes.DECREASE_REMAINING_ATTEMPTS,
  };
};

// Save game result

const saveGameResultStart = () => {
  return {
    type: actionTypes.SAVE_GAME_RESULT_START,
  };
};

const saveGameResultSuccess = (result) => {
  return {
    type: actionTypes.SAVE_GAME_RESULT_SUCCESS,
    result,
  };
};

const saveGameResultError = (error) => {
  return {
    type: actionTypes.SAVE_GAME_RESULT_ERROR,
    error,
  };
};

export const saveGameResultCleanError = () => {
  return {
    type: actionTypes.SAVE_GAME_RESULT_CLEAN_ERROR,
  };
};

export const saveGameResult = (id, result) => {
  let endpoint = `game/${id}/result`;
  return (dispatch) => {
    dispatch(saveGameResultStart());
    return customaxios
      .post(endpoint, { result })
      .then((response) => {
        dispatch(saveGameResultSuccess(response));
      })
      .catch((error) => {
        dispatch(saveGameResultError(error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

export const acceptGameRules = (id) => {
  let endpoint = `game/vinciancoradipiu/${id}/acceptpromotionprivacy`;
  return (dispatch) => {
    return customaxios
      .post(endpoint)
      .then((response) => {
        dispatch(acceptGameRulesSuccess());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const acceptGameRulesSuccess = () => {
  return {
    type: actionTypes.ACCEPT_GAME_RULES_SUCCESS,
  };
};
