import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Form from "../../components/Games/Form";
import * as actionCreators from "../../store/actions";

const FormValidation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const wins = useSelector((state) => state.user.user.wins);
  const game = useSelector((state) => state.promo.game);

  const [win, setWin] = useState(null);
  const [validationCompleted, setValidationCompleted] = useState(false);

  useEffect(() => {
    if (wins && wins.length > 0) {
      const find = wins.find(
        (win) =>
          win.pending === true &&
          win.game_type !== "survey" &&
          win.game_type !== "quiz"
      );

      if (find) {
        setWin(find);
        dispatch(
          actionCreators.getGame(find.game_id, "vinciancoradipiu", "", "skip")
        );
      } else {
        if (!validationCompleted) {
          history.push("/");
        }
      }
    }
  }, [history, wins]);

  return (
    <FormValidationWrapper>
      {game[0] && win && game[0].id === win.game_id && (
        <Form
          form={game[0].form}
          formResponse={null}
          lang={"it"}
          id={game[0].id}
          playslog={win.id}
          played={true}
          completed={() => setValidationCompleted(true)}
          game_type={win.game_type}
        />
      )}
    </FormValidationWrapper>
  );
};

const FormValidationWrapper = styled.div`
  margin-top: 100px;
`;

export default FormValidation;
