import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    box-sizing: border-box;
    font-size: 100%;
    height: 100%;
  }
  body {
    font-family: ${(props) => props.font}, sans-serif;
    font-size: 100%;
    height: 100%;
    color: #3A3C42;
    font-size: 14px;
    background-image: url("/images/body-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  * {
    margin: 0;
    padding: 0;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  a {
    text-decoration: none;
  }
  .container {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .slick-disabled {
    opacity: 0.5;
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }
  p {
    line-height: 1.4;
  }
  ul {
    list-style-type: none;
  }
  textarea {
    font-family: ${(props) => props.font}, sans-serif;
  }
  .prizes-list .slick-track {
    margin-left: -65px !important;
  }

  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }

  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-50 {
    margin-top: 50px;
  }

  .giovediImg {
    padding: 12px;
  }
  .text-center {
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    .prizes-list .slick-track {
      margin-left: 0 !important;
    }
  }
`;

export default GlobalStyle;
