export const signinform = (ref, inModal) => {
  return {
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        name: "email",
        id: "email",
        placeholder: "Email",
        ref,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        name: "password",
        id: "password",
        placeholder: "Password",
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
  };
};

export const registrationform = (
  email,
  firstname,
  lastname,
  psw,
  pswrepeat,
  pswerror,
  pswconfirmerror,
  fiscalcode,
  phone,
  district,
  city,
  zipcode,
  company,
  mgm
) => {
  return {
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        name: "email",
        id: "email",
        placeholder: `${email}`,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    dob: {
      elementType: "datepicker",
      elementConfig: {
        placeholder: "Data di nascita",
        name: "dob",
      },
      label: "",
      value: "",
      valuetosend: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        name: "password",
        id: "password",
        placeholder: `${psw}`,
      },
      label: "",
      value: "",
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: "input",
      elementConfig: {
        type: "password",
        name: "repeatpassword",
        id: "repeatpassword",
        placeholder: `${pswrepeat}`,
      },
      label: "",
      value: "",
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
    firstname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "firstname",
        id: "firstname",
        placeholder: `${firstname}`,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    lastname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "lastname",
        id: "lastname",
        placeholder: `${lastname}`,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    phone: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "phone",
        id: "phone",
        placeholder: `${phone}`,
      },
      label: "",
      value: "",
      validation: {
        required: false,
        touched: false,
      },
      valid: false,
    },
    fiscalcode: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "fiscalcode",
        id: "fiscalcode",
        placeholder: `${fiscalcode}`,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    mgm: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "mgm",
        id: "mgm",
        placeholder: mgm,
      },
      label: "",
      value: "",
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    district: {
      elementType: "select",
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: "",
            displayValue: `Seleziona ${district.toLowerCase()}`,
            disabled: true,
          },
        ],
        name: "district",
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    city: {
      elementType: "select",
      isCity: true,
      elementConfig: {
        options: [
          {
            value: "",
            displayValue: `Seleziona ${city.toLowerCase()}`,
            disabled: true,
          },
        ],
        name: "city",
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    zipcode: {
      elementType: "input",
      isZipcode: true,
      elementConfig: {
        type: "text",
        name: "zipcode",
        id: "zipcode",
        placeholder: zipcode,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gdpr_1: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        holder: "",
        name: "gdpr_1",
        id: "gdpr_1",
      },
      label:
        "<p>Dichiaro di aver letto e accettato il <a href='https://plugandplay.nextgroup.eu/storage/alg/regolamento.pdf' target='_blank'>regolamento e di essere titolare di una partita IVA.</a></p>",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    accettazione_regolamento: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        placeholder: "",
        name: "accettazione_regolamento",
        id: "accettazione_regolamento",
      },
      label:
        "<p>Autorizzo al trattamento dei dati per le finalità indicate come obbligatorie nell’<a href='https://plugandplay.nextgroup.eu/storage/alg/privacy.pdf' target='_blank'>informativa privacy</a></p>",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    company: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "company",
        id: "company",
        placeholder: `${company}`,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const recoveryPasswordForm = (emailLabel, ref) => {
  return {
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        name: "email",
        id: "email",
        placeholder: `${emailLabel}`,
        ref,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const resetPasswordForm = (
  newPswLabel,
  confirmNewPsw,
  pswerror,
  pswconfirmerror,
  ref
) => {
  return {
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        name: "password",
        id: "password",
        placeholder: newPswLabel,
        ref,
      },
      label: "",
      value: "",
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: pswerror,
      valid: false,
    },
    confirmpassword: {
      elementType: "input",
      elementConfig: {
        type: "password",
        name: "confirmpassword",
        id: "confirmpassword",
        placeholder: confirmNewPsw,
      },
      label: "",
      value: "",
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: pswconfirmerror,
      valid: false,
    },
  };
};

export const uploadReceiptForm = (dateLabel, numberLabel, amountLabel) => {
  return {
    receipt: {
      elementType: "inputfile",
      elementConfig: {
        type: "file",
        accept: "image/*,application/pdf",
        placeholder: "Carica la fattura",
        name: "receipt",
        id: "receipt",
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    },
    date: {
      elementType: "datepicker",
      elementConfig: {
        placeholder: dateLabel,
        name: "date",
      },
      label: "",
      value: "",
      valuetosend: "",
      validation: {
        required: true,
        touched: false,
        minDate: new Date("2021-05-01"),
        maxDate: new Date("2021-06-30"),
      },
      valid: false,
      title: "Dati fattura",
    },
    number: {
      elementType: "input",
      elementConfig: {
        type: "number",
        name: "number",
        id: "number",
        placeholder: numberLabel,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    amount: {
      elementType: "input",
      elementConfig: {
        type: "number",
        name: "amount",
        id: "amount",
        placeholder: amountLabel,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const contactForm = () => {
  return {
    topic: {
      elementType: "select",
      elementConfig: {
        options: [
          { value: "", displayValue: `Seleziona argomento`, disabled: true },
          { value: "Premio", displayValue: "Premio" },
          { value: "Gioco", displayValue: "Gioco" },
          { value: "Altro", displayValue: "Altro" },
        ],
        placeholder: `Seleziona argomento`,
        name: "topic",
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    message: {
      elementType: "textarea",
      elementConfig: {
        name: "message",
        id: "message",
        placeholder: "Il tuo messaggio",
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const profileform = (
  shipment_firstname,
  shipment_lastname,
  address1,
  city,
  district,
  zipcode,
  address2
) => {
  return {
    shipment_firstname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "shipment_firstname",
        id: "shipment_firstname",
        placeholder: "Nome",
      },
      label: "",
      value: shipment_firstname,
      validation: {
        required: true,
        touched: false,
      },
      valid: shipment_firstname ? true : false,
    },
    shipment_lastname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "shipment_lastname",
        id: "shipment_lastname",
        placeholder: `Cognome`,
      },
      label: "",
      value: shipment_lastname,
      validation: {
        required: true,
        touched: false,
      },
      valid: shipment_lastname ? true : false,
    },
    address1: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "address1",
        id: "address1",
        placeholder: "Indirizzo",
      },
      label: "",
      value: address1,
      validation: {
        required: true,
        touched: false,
      },
      valid: address1 ? true : false,
    },
    city: {
      elementType: "select",
      isCity: true,
      elementConfig: {
        options: [
          {
            value: city || "",
            displayValue: city || "Seleziona città",
            disabled: true,
          },
        ],
        name: "city",
      },
      label: "",
      value: city || "",
      validation: {
        required: true,
        touched: false,
      },
      valid: city ? true : false,
    },
    district: {
      elementType: "select",
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: "",
            displayValue: "Seleziona provincia",
            disabled: true,
          },
        ],
        name: "district",
      },
      label: "",
      value: district,
      validation: {
        required: true,
        touched: false,
      },
      valid: district ? true : false,
    },
    zipcode: {
      elementType: "input",
      isZipcode: true,
      elementConfig: {
        type: "text",
        name: "zipcode",
        id: "zipcode",
        placeholder: zipcode || "CAP",
      },
      label: "",
      value: zipcode,
      validation: {
        required: true,
        touched: false,
      },
      valid: zipcode ? true : false,
    },
    address2: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "address2",
        id: "address2",
        placeholder: "Note (opzionale)",
      },
      label: "",
      value: address2 || "",
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};

export const filtersform = (max_points) => {
  return {
    search: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "search",
        id: "search",
        placeholder: "Cerca tra i premi",
      },
      label: "",
      value: "",
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    category: {
      elementType: "select",
      isCategories: true,
      elementConfig: {
        options: [
          {
            value: "",
            displayValue: `Seleziona categoria`,
            disabled: true,
          },
        ],
        name: "category",
      },
      label: "",
      value: "",
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    range: {
      elementType: "inputrange",
      elementConfig: {
        name: "range",
        id: "range",
      },
      label: "",
      value: { min: 0, max: max_points },
      maxValue: max_points,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    only_reacheble: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        placeholder: "",
        name: "only_reacheble",
        id: "only_reacheble",
      },
      label: "Visualizza solo premi richiedibili",
      value: "",
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};

export const firstAccessForm = () => {
  return {
    rules: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        placeholder: "",
        name: "rules",
        id: "rules",
      },
      label: `<span><a target='_blank' href='${
        process.env.PUBLIC_URL +
        "/pdf/Regolamento_OP_MyLinkPlus_2021-2022-2023-2024.pdf"
      }'/>Regolamento MYLINKPLUS</a>
      <span class="spansmall"> e </span> <a target='_blank' href='${
        process.env.PUBLIC_URL + "/pdf/TerminiDiServizio.pdf"
      }'/>termini di servizio*</a></span>`,
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    privacy: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        placeholder: "",
        name: "privacy",
        id: "privacy",
      },
      label: `<a target='_blank' href='${
        process.env.PUBLIC_URL + "/pdf/informativa-privacy.pdf"
      }'/>Informativa Privacy*</a>`,
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    accept_launch_contest_rules_23: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        placeholder: "",
        name: "accept_launch_contest_rules_23",
        id: "accept_launch_contest_rules_23",
      },
      label: `<span><a target='_blank' href='${
        process.env.PUBLIC_URL +
        "/pdf/Regolamento_Linkem_Vinci_ancora_di_più.pdf"
      }'/>Regolamento concorso di lancio e continuativo</a> </span>`,
      value: "0",
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};

export const RushAndWinForm = (firstAccess) => {
  return {
    rules: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        placeholder: "",
        name: "rules",
        id: "rules",
      },
      label: `<span><a target='_blank' href='${
        process.env.PUBLIC_URL +
        "/pdf/Regolamento_OP_MyLinkPlus_2021-2022-2023-2024.pdf"
      }'/>Regolamento MYLINKPLUS</a> <span class="spansmall"> e </span> <a target='_blank' href='${
        process.env.PUBLIC_URL + "/pdf/TerminiDiServizio.pdf"
      }'/>termini di servizio*</a></span>`,
      value: "0",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    privacy: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        placeholder: "",
        name: "privacy",
        id: "privacy",
      },
      label: `<a target='_blank' href='${
        process.env.PUBLIC_URL + "/pdf/informativa-privacy.pdf"
      }'/>Informativa Privacy*</a>`,
      value: "0",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    accept_launch_contest_rules_23: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        placeholder: "",
        name: "accept_launch_contest_rules_23",
        id: "accept_launch_contest_rules_23",
      },
      label: `<span><a target='_blank' href='${
        process.env.PUBLIC_URL +
        "/pdf/Regolamento_Linkem_Vinci_ancora_di_più.pdf"
      }'/>Regolamento concorso</a> </span>`,
      value: "0",
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};
