import React from "react";
import { Button, Text } from "../../components/Ui";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { respondTo } from "../../theme/mixin";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import SingleFaq from "./SingleFaq";
import { useSelector } from "react-redux";
import moment from "moment";
const FaqWrapper = styled.div`
  .slide {
    position: relative;
    padding: 90px 15px 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      margin-top: 15px;

      ${respondTo.md`
        top: 0;
        position: absolute;
        width: 100%;
        max-width: 270px;
        height: 100%;
        margin-top: 0;
      `}

      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }

  .faq-list-wrapper {
    margin-top: 50px;
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .faq-footer {
      margin-top: 50px;
      text-align: center;

      h4 {
        color: #eb5a3f;
        font-size: 28px;
        font-weight: 300;
        margin-bottom: 25px;
      }

      button {
        margin: 0 auto;
      }
    }
  }
`;

const Faq = () => {
  const { title, content } = useSelector(
    (state) => state.cms.faq?.[0]?.contents?.[0].i18l || {}
  );
  const checkIfPastTargetDate = () => {
    const targetDate = moment("2024-06-28T00:00:00");
    const now = moment();

    return now.isAfter(targetDate);
  };
  return (
    <FaqWrapper>
      <div className="slide">
        <img src={process.env.PUBLIC_URL + "/images/faq-slide.jpg"} alt="" />

        <div className="content">
          <div>
            <Text as="h3">{title}</Text>
            <Text>{content}</Text>
          </div>
        </div>
      </div>

      <div className="faq-list-wrapper">
        <SingleFaq
          question="Cos’è MYLINKPLUS?"
          answer="MYLINKPLUS è il programma fedeltà di Linkem. Tramite MYLINKPLUS puoi
          accedere al catalogo premi, verificare i punti accumulati e accumularne di nuovi, approfittare delle offerte dei 
          nostri partner, rispondere ai nostri sondaggi e tentare la fortuna partecipando ai concorsi a premi che ti proporremo.
          Per maggiori dettagli, consulta il REGOLAMENTO."
          even
        />
        <SingleFaq
          question="Come faccio a navigare nel sito MYLINKPLUS?"
          answer="Passando con il mouse sopra l’icona composta da tre linee presente in alto a destra, si aprirà un menu con
          tutte le sezioni del sito. Accanto quest’icona, troverai anche quelle delle sezioni CERCA,
          CARRELLO e NOTIFICHE."
        />
        <SingleFaq
          question="Cos’è la sezione Premiati di +?"
          answer="Nella sezione PREMIATI DI + è presente il catalogo premi di MYLINKPLUS: potrai consultare l’elenco
          completo dei premi, il valore in punti e i dettagli di ognuno. Avrai la possibilità di aggiungere i tuoi premi preferiti nella WISHLIST,
          per non perderli di vista mentre continui ad accumulare punti, o direttamente nel CARRELLO, se avrai raggiunto già i punti sufficienti
          per ottenerli."
          even
        />
        <SingleFaq
          question="Cos’è la wishlist della sezione Premiati di +?"
          answer="Se ti piace un premio del nostro catalogo ma non hai ancora accumulato abbastanza punti per richiederlo, potrai utilizzare il pulsante
          a forma di cuore (presente sotto ogni premio e nelle pagine di dettaglio) e salvarlo nella lista personalizzata dei tuoi premi preferiti: la wishlist.
          In questa sezione e nelle anteprime presenti (in HOMEPAGE e nella pagina PREMIATI di + potrai verificare) quanti punti ti mancano per i tuoi premi
          preferiti, avrai la possibilità di spostarli direttamente nel CARRELLO quando avrai accumulato i punti sufficienti per richiederli o potrai rimuoverli dalla wishlist."
        />
        <SingleFaq
          question="Cos’è il carrello?"
          answer="Quando clicchi sull’icona a forma di busta per gli acquisti presente sotto
          ogni premio e nelle relative pagine di dettaglio, il premio viene automaticamente inserito nella sezione
          CARRELLO. Potrai raggiungere la sezione CARRELLO anche direttamente dal menu presente in alto a destra, 
          cliccando sempre sull’icona a forma di busta per gli acquisti. Quando sono presenti dei premi nel carrello,
          l’icona presenterà su un lato un cerchietto colorato. All’interno della pagina CARRELLO, se avrai accumulato
          abbastanza punti, potrai procedere direttamente all’ordine dei premi confermando l’indirizzo di spedizione o
          inserendolo, se non lo hai già indicato in precedenza."
          even
        />
        <SingleFaq
          question="Cos’è la sezione Club +?"
          answer="È la sezione in cui sono presenti i vantaggi che ti ha dedicato MYLINKPLUS: qui potrai consultare
          l’elenco dei nostri partner che offrono degli sconti, privilegi e offerte dedicate ai clienti Linkem registrati al
          programma fedeltà MYLINKPLUS. Cliccando sul logo del singolo partner potrai leggere in dettaglio la
          descrizione dell’offerta che ti ha riservato. Non avrai bisogno di accumulare punti, potrai scegliere subito
          l’offerta del partner più giusta per te e iniziare lo shopping.
          Nuovi partner e nuovi vantaggi saranno aggiornati periodicamente durante l’anno."
        />
        <SingleFaq
          question="Cos’è la sezione Conosciamoci di +?"
          answer="È la sezione in cui trovi i sondaggi riservati agli utenti MYLINKPLUS: rispondendo alle
          domande, potrai accumulare punti da spendere nel nostro catalogo premi della sezione PREMIATI DI +."
          even
        />
        {checkIfPastTargetDate() ? (
          ""
        ) : (
          <SingleFaq
            question="Cos’è la sezione Vinci di +?"
            answer="È la sezione in cui potrai partecipare ai concorsi che ti proporremo
          durante l’anno per provare a vincere premi esclusivi. MYLINKPLUS ricompensa anche la tua fedeltà: da
          + tempo sei nostro cliente e + possibilità di gioco hai a disposizione."
          />
        )}

        {/* <SingleFaq
          question="Cos’è l’icona a forma di campanella presente nel menu?"
          answer="L’icona a forma di campanella rappresenta le notifiche di MYLINKPLUS: ogni volta che dovremo
          comunicarti qualche notizia troverai un cerchietto colorato accanto a questa icona. Se passi sopra l’icona con il mouse,
          vedrai un’anteprima delle news che hai ancora da leggere mentre, se ci clicchi,
          potrai accedere direttamente alla pagina in cui sono presenti tutte le comunicazioni."
          even
        /> */}
        {/* <SingleFaq
          question="Come faccio a effettuare una ricerca nel sito?"
          answer="Nel menu in alto a destra trovi un’icona a forma di lente d’ingrandimento: passandoci sopra con il
          mouse, apparirà un menu con il campo per inserire l’oggetto della tua ricerca. Per effettuare una
          ricerca, ti basterà indicare l’argomento o l’informazione che vuoi cercare 
          e premere il pulsante CERCA o il tasto INVIO sulla tua tastiera."
        /> */}
        <SingleFaq
          question="Come faccio a sapere quanti punti ho accumulato finora?"
          answer="Potrai visualizzare in qualsiasi momento il tuo saldo punti nel menu in
          alto a destra passando con il mouse sull’icona del profilo. Potrai visualizzare il dettaglio
          dei tuoi punti anche direttamente nella pagina IL TUO PROFILO."
          even
        />
        <SingleFaq
          question="Come faccio ad accumulare punti?"
          answer="Accumulare punti è molto semplice: ti basterà, ad esempio, saldare le fatture Linkem o
          effettuare una ricarica, acquistare servizi Linkem, passare dall’offerta prepagata a
          quella in abbonamento o rispondere ai sondaggi presenti nella sezione CONOSCIAMOCI DI +. Per
          consultare l’elenco completo delle attività che ti permettono di accumulare punti,
          consulta la pagina COME FUNZIONA o leggi il REGOLAMENTO."
        />
        <SingleFaq
          question="Come faccio a richiedere i premi?"
          answer="Quando avrai accumulato i punti sufficienti per richiedere uno dei tuoi premi
          preferiti, ti basterà inserirlo nel CARRELLO cliccando
          sull’apposita icona, presente sotto ogni premio o in ogni pagina di dettaglio.
          Dalla pagina CARRELLO, potrai procedere all’ordine cliccando su CHECKOUT e
          confermando l’indirizzo di spedizione o inserendolo, se non lo hai già indicato in precedenza."
          even
        />
        <SingleFaq
          question="Come faccio a richiedere i vantaggi del Club +?"
          answer="Per scegliere un’offerta di un partner del Club + ti basterà selezionare il partner che
          desideri, andare nella relativa pagina di dettaglio e cliccare sul pulsante RICHIEDI. Nella
          stessa pagina, ti apparirà il codice sconto da copiare e utilizzare direttamente sul sito del partner, 
          utilizzando il tasto COPIA e poi quello VAI AL SITO DEL PARTNER. In alcuni casi, invece del
          codice, otterrai un link che ti condurrà su un sito esterno rispetto a MYLINKPLUS con una
          selezione di articoli già scontati a cura del nostro partner."
        />
        <SingleFaq
          question="Cosa significa l’espressione “Sei un cliente New, 2+, 5+, 10+” presente nel mio
          profilo?"
          answer="MYLINKPLUS premia la tua fedeltà: da + tempo sei nostro cliente e + possibilità di gioco hai a disposizione,
          in particolare:
          <br />
          - NEW, se sei un (nuovo cliente fino al compimento del secondo anno in Linkem: avrai 1 chance di
          gioco
          <br />
          - 2+, se sei un (cliente dai 2 al compimento dei 5 anni in Linkem: avrai) 2 chance di gioco
          <br />
          - 5+, se sei un (cliente dai 5 al compimento dei 10 anni in Linkem: avrai) 5 chance di gioco
          <br />
          - 10+, se sei un (cliente da oltre 10 anni: avrai) 10 chance di gioco"
          even
        />
        <SingleFaq
          question="Come faccio a inserire il mio indirizzo di spedizione?"
          answer="Nella pagina IL TUO PROFILO, in basso, è presente un box dal quale consultare, modificare o inserire
          l’indirizzo di spedizione presso il quale spedire i premi che hai richiesto."
        />
        <SingleFaq
          question="Come faccio a cancellare la mia iscrizione a MYLINKPLUS?"
          answer="Ci dispiace che tu voglia lasciarci ma, se vuoi cancellare la tua iscrizione al programma
          fedeltà MYLINKPLUS, ti basterà andare nella pagina IL TUO PROFILO, scorrere fino al box DATI PERSONALI e
          premere il su GESTIONE PROFILO. Dalla finestra che ti apparirà potrai confermare la cancellazione della tua iscrizione a MYLINKPLUS."
          even
        />

        <div className="faq-footer">
          <h4>Non hai ancora trovato quello che cercavi?</h4>

          <Link to="contatti">
            <Button upper gradient={{ left: "#EB5A3F", right: "#FDCA46" }}>
              Contattaci <ChevronRight size={30} />
            </Button>
          </Link>
        </div>
      </div>
    </FaqWrapper>
  );
};

export default Faq;
